.Home-search{
    padding: 40px 0;
    text-align: center;
    padding-bottom: 60px;
}
h2{
    margin-bottom: 20px;
    font-size: 32px;
    text-align: center;
}
input[type=text]{
    border: 1px solid #ddd!important;
    padding: 0 24px;
    border-radius: 8px;
    line-height: 54px;
}
.Home-crypto{
    margin-bottom: 20px;
    font-size: 26px;

}
.Home-Crypto-List{
    border-top: 1px solid #ddd;
}