.Crypto-List a {

    display: flex;
    gap: 30px;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}
.Crypto-image{
    width: 10%;
}
.Crypto-name{
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    color: black;
}
.Crypto-List span{
    display: block ;
}
.Crypto-BTC{
    font-size: 15px;
    color: black;   
}
.Crypto-BTC img{
    width: 16px;
    display: inline-block;
    margin: -3px 5px 0 0;
    vertical-align: middle;
}
.Crypto-USD{
    font-size: 12px;
    color: black;
}