.Header{
    padding: 20px 0;
    background: #111;
    color: #FFF;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
    position: sticky;
    top: 0;
    z-index: 10;
    h1 a{
        color: white;
    }
    
}
.Header a{
    color: white;
}
.Width{
    position: relative;
}
svg{
    position: absolute;
    top: 50%;
    left: 0%;
    margin-top: -12px;
    
}