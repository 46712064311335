*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.Width{
    max-width: 600px;
    margin: 0 auto;
    width: 92%;
}
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
a {
    text-decoration: none;
}

input[type = text]{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    border-radius: 8px;
}
img{
    max-width: 100%;
    display: block;
}